'use strict';

const Drift = require('drift-zoom').default;
const Swiper = require('swiper/bundle').Swiper;
const ProductGalleryHelper = require('./productGalleryHelper');
const breakpoints = require('../breakpoints');

module.exports = function () {
    const thumbnailsElement = document.querySelector('.js-product-gallery-thumbnails');
    const productGalleyElement = document.querySelector('.js-product-gallery');

    if (!thumbnailsElement || !productGalleyElement) {
        return;
    }

    const thumbnailSlideConfig = JSON.parse(thumbnailsElement.dataset.sliderConfig);
    const thumbnailsSwiperElement = thumbnailsElement.querySelector('.swiper');
    const thumbnailsSwiper = new Swiper(thumbnailsSwiperElement, thumbnailSlideConfig);

    const productGalleySlideConfig = JSON.parse(productGalleyElement.dataset.sliderConfig);
    const productGalleySwiperElement = productGalleyElement.querySelector('.swiper');
    const productGalleySwiper = new Swiper(productGalleySwiperElement, productGalleySlideConfig);

    const swiperThumbSlides = thumbnailsElement.querySelectorAll('.swiper-slide');
    const galleryHelper = new ProductGalleryHelper(swiperThumbSlides, productGalleySwiper, ['mouseover', 'click']);
    galleryHelper.bindEventsThumbnailSlides();

    if (breakpoints.down('lg')) {
        productGalleySwiper.on('slideChange', () => {
            thumbnailsSwiper.slideTo(productGalleySwiper.realIndex);
        });
    }

    // Enabled the drift zoom for the desktop images;
    if (breakpoints.up('lg')) {
        const productImages = document.querySelectorAll('.product-gallery .zoom-image');
        productImages.forEach((productImage) => {
            // eslint-disable-next-line no-new
            new Drift(productImage, {
                paneContainer: productImage?.parentElement
            });
        });
    }
};

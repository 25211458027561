'use strict';

const breakpoints = require('../breakpoints');

class ProductAccordionHelper {
    scrollToSlot(slot) {
        this.slot = slot;

        if (!this.slot) {
            return;
        }

        const slotBody = this.slot.querySelector('.accordion-collapse');
        const accordion = this.slot.closest('.product-detail-content-accordion');
        const slotRect = this.slot.getBoundingClientRect();
        const windowOffset = window.pageYOffset;
        const slotOffset = windowOffset + slotRect.top;

        const nav = accordion?.querySelector('.js-accordion-nav-wrapper');
        const navRect = nav.getBoundingClientRect();

        if (!nav || !navRect) {
            return;
        }

        const navOffset = windowOffset + navRect.top;
        const navHeight = navRect.height;
        const navBottom = navOffset + navHeight;

        // nav is sticky so we need to scroll below it
        let scrollTo = slotOffset - navHeight;

        const header = document.querySelector('header');
        const headerRect = header?.getBoundingClientRect();

        // header only shows on mobile when scolling up
        if (headerRect && (breakpoints.up('md') || navBottom > slotOffset)) {
            scrollTo -= headerRect.height;
        }

        slotBody?.classList?.add('show');

        window.scrollTo({ top: scrollTo, behavior: 'smooth' });
    }
}

module.exports = ProductAccordionHelper;

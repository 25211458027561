'use strict';

const ProductAccordionHelper = require('./productAccordionHelper');

class ProductAccordion {
    constructor(component) {
        this.component = component;
        this.nav = this.component.querySelector('.js-accordion-nav-wrapper');
        this.header = document.querySelector('header');
        this.slots = this.component.querySelectorAll('.product-detail-content-accordion .js-slot');
    }

    readyCallback() {
        if (!this.nav || !this.header || !this.slots) {
            return;
        }

        this.mapEvents();

        if (window.location.hash) {
            this.slots.forEach((slot) => {
                const slotId = slot.id;
                if (window.location.hash === `#${slotId}`) {
                    const button = this.nav.querySelector(`#${slotId}-slot`);
                    setTimeout(() => {
                        button?.click();
                    }, 300);
                }
            });
        }
    }

    mapEvents() {
        window.addEventListener('resize', ()=> {
            this.responsiveAndStickyNavBehavior();
        });
        window.addEventListener('orientationchange', () => {
            this.responsiveAndStickyNavBehavior();
        });
        window.addEventListener('scroll', () => {
            this.responsiveAndStickyNavBehavior();
        });

        this.repositionNavOnHeaderChange();

        const slotButtons = this.component?.querySelectorAll('.js-accordion-nav-link');
        slotButtons.forEach((button) => {
            button.addEventListener('click', (event) => {
                this.slotButtonClickHandler(event);
            });
        });
    }

    responsiveAndStickyNavBehavior() {
        const windowOffset = window.pageYOffset;
        const headerHeight = this.header?.clientHeight;
        const offsetBelowHeader = windowOffset + headerHeight;
        const offsetBelowHeaderAndNav = offsetBelowHeader + (this.nav.clientHeight);

        this.slots.forEach((slot, key, array) => {
            const slotId = slot.id;
            const button = this.nav.querySelector(`#${slotId}-slot`);
            if (!button) {
                return;
            }

            const slotRect = slot.getBoundingClientRect();
            const slotStart = windowOffset + slotRect.top;
            const slotEnd = slotStart + slotRect.height;

            let startCheck = slotStart;
            if (key === 0) {
                startCheck = 0;
            } else {
                const previousSlot = array[key - 1];
                const previousSlotRect = previousSlot.getBoundingClientRect();
                const previousSlotEnd = windowOffset + previousSlotRect.top + previousSlotRect.height;

                startCheck = previousSlotEnd;
            }

            if (
                (offsetBelowHeaderAndNav >= startCheck && offsetBelowHeaderAndNav < slotEnd)
                || (offsetBelowHeaderAndNav > slotEnd && key === array.length - 1)
            ) {
                if (!button.classList.contains('active')) {
                    button.classList.add('active');
                    button.setAttribute('aria-selected', 'true');
                    this.scrollButtonIntoView(button);
                }
            } else {
                button.classList.remove('active');
                button.setAttribute('aria-selected', 'false');
            }
        });
    }

    repositionNavOnHeaderChange() {
        const headerHeight = this.header.clientHeight;
        const observer = new IntersectionObserver((entries) => {
            this.nav.style.top = (entries[0]?.isIntersecting) ? `${headerHeight}px` : '0px';
        }, {});
        observer.observe(this.header);
    }

    scrollButtonIntoView(button) {
        if (!button) {
            return;
        }
        let positionLeft = button.offsetLeft;
        const previousSibling = button.closest('li').previousElementSibling;
        if (previousSibling) {
            positionLeft = previousSibling.offsetLeft;
        }
        this.nav.scrollTo({ left: positionLeft, behavior: 'smooth' });
    }

    slotButtonClickHandler(event) {
        if (!event.target) {
            return;
        }
        const navButton = event.target;
        const scrollToElementId = navButton.getAttribute('data-slot-id');
        const slotButton = this.component.querySelector(`#${scrollToElementId}`);
        const slot = slotButton.closest('.js-slot');

        const productAccordionHelper = new ProductAccordionHelper();

        if (slotButton?.classList.contains('collapsed')) {
            slotButton?.classList.remove('collapsed');
        }

        productAccordionHelper.scrollToSlot(slot);
    }
}

module.exports = function () {
    const ckProductAccordionComponent = document.querySelector('.product-detail-content-accordion');

    if (ckProductAccordionComponent) {
        const ckProductAccordion = new ProductAccordion(ckProductAccordionComponent);
        ckProductAccordion.readyCallback();
    }
};

'use strict';

const swiperSlideClass = 'swiper-slide';
const swiperSlideActiveClass = 'swiper-slide-active-custom';

class ProductGalleryHelper {
    constructor(
        thumbnailSlides,
        gallery,
        thumbnailEvents
    ) {
        if (!gallery) {
            return;
        }

        this.thumbnailSlides = thumbnailSlides;
        this.thumbnailEvents = thumbnailEvents;
        this.gallery = gallery;

        this.gallery?.on('slideChange', () => {
            const currentIndex = this.gallery?.slides[this.gallery.activeIndex]?.getAttribute('data-slide-index');
            this.gallery?.slides[this.gallery.activeIndex]?.classList.remove('invisible');
            if (currentIndex) {
                this.thumbnailSlides.forEach((thumb) => {
                    if (thumb.getAttribute('data-slide-index') === currentIndex) {
                        this.setCurrentProductSlideAsSelectedThumbnail(thumb);
                    }
                });
            }
        });
    }

    bindEventsThumbnailSlides() {
        this.thumbnailSlides.forEach(thumbSlide => {
            this.thumbnailEvents.forEach(evtType => {
                thumbSlide.addEventListener(evtType, (evt) => {
                    evt.stopPropagation();

                    const selectedSlide = evt.target;
                    this.changeSlide(selectedSlide);
                });
            });
        });
    }

    changeSlide(slideElement) {
        const slideIndex = parseInt(slideElement?.closest(`.${swiperSlideClass}`)?.getAttribute('data-slide-index'), 10);
        this.changeSlideToIndex(slideIndex);
        this.setCurrentProductSlideAsSelectedThumbnail(slideElement);
    }

    changeSlideToIndex(index) {
        if (!this.thumbnailSlides[index]) {
            return;
        }

        this.gallery?.slideToLoop(index);
    }

    setCurrentProductSlideAsSelectedThumbnail(slideElement) {
        this.thumbnailSlides.forEach((slide) => slide.classList.remove(`${swiperSlideActiveClass}`));
        slideElement?.closest(`.${swiperSlideClass}`)?.classList?.add(`${swiperSlideActiveClass}`);
    }

    setSelectedThumbnailToIndex(index) {
        if (!this.thumbnailSlides[index]) {
            return;
        }

        this.thumbnailSlides.forEach((slide, slideIndex) => {
            if (slideIndex === index) {
                slide.classList.add(`${swiperSlideActiveClass}`);
            } else {
                slide.classList.remove(`${swiperSlideActiveClass}`);
            }
        });
    }

    // eslint-disable-next-line class-methods-use-this
    scrollButtonIntoView(
        target,
        scrollableWrapper,
        enableSmoothScroll
    ) {
        if (!target) {
            return;
        }
        const positionLeft = target.offsetLeft;

        const options = { left: positionLeft - 2 };
        if (enableSmoothScroll === true) {
            options[`${'behavior'}`] = 'smooth';
        }
        scrollableWrapper.scrollTo(options);
    }

    // eslint-disable-next-line class-methods-use-this
    clickImageToggleClassScrollIntoView(
        images,
        target,
        targetToggleClass
    ) {
        images.forEach((image) => {
            image.addEventListener('click', e => {
                const rectImage = e.target?.getBoundingClientRect();
                const yClickImage = e.clientY - rectImage.top;
                const heightImage = image.clientHeight;
                let scrollIntoViewBlock = 'center';

                if (yClickImage < heightImage / 3) {
                    scrollIntoViewBlock = 'start';
                } else if ((yClickImage > 2) * (heightImage / 3)) {
                    scrollIntoViewBlock = 'end';
                }

                target.classList.toggle(targetToggleClass);

                image.scrollIntoView({
                    behavior: 'auto',
                    block: `${scrollIntoViewBlock}`
                });
            });
        });
    }
}

module.exports = ProductGalleryHelper;

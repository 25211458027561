'use strict';

var throttle = require('lodash/throttle');

class ProductCtaBox {
    constructor(component, header, attributes) {
        this.component = component;
        this.container = this.component.closest('.container');
        this.productDetailInfo = this.component.querySelector('.product-detail-info');

        this.header = header;
        this.attributes = attributes;
    }

    readyCallback() {
        if (!this.component) {
            return;
        }

        this.tooTall = this.checkTooTall();

        if (this.tooTall) {
            this.mapStickyEvents();
            this.stickyBehavior();
        }

        if (!this.productDetailInfo) {
            return;
        }

        this.mapThumbnailEvents();
        this.thumbnailBehavior();
    }

    mapStickyEvents() {
        const callback = throttle(() => {
            this.stickyBehavior();
        }, 500);

        window.addEventListener('resize', callback);
        window.addEventListener('orientationchange', callback);
        window.addEventListener('scroll', callback);
    }

    mapThumbnailEvents() {
        const callback = throttle(() => {
            this.thumbnailBehavior();
        }, 500);

        window.addEventListener('resize', callback);
        window.addEventListener('orientationchange', callback);
        window.addEventListener('scroll', callback);
    }

    checkTooTall() {
        const containerTop = this.container.getBoundingClientRect().top + window.scrollY;
        const boxHeight = this.component.offsetHeight;
        const viewportHeight = window.innerHeight;

        return (containerTop + boxHeight > viewportHeight);
    }

    stickyBehavior() {
        const containerTop = this.container.getBoundingClientRect().top;
        const boxTop = this.component.getBoundingClientRect().top;
        const boxBottom = this.component.getBoundingClientRect().bottom + this.component.offsetHeight - window.innerHeight;

        let sticky = true;
        let invisible = false;
        if (!this.component.classList.contains('not-sticky') && containerTop === boxTop) {
            // stick to top
            sticky = false;
        }

        if (this.component.classList.contains('not-sticky')) {
            if (boxBottom < -5) {
                // we're out of sight
                invisible = true;
            }

            if (boxBottom > -30) {
                // keep stuck to top
                sticky = false;
            }
        }

        if (sticky) {
            this.component.classList.remove('not-sticky');
            this.component.classList.add('condensed');
        } else {
            this.component.classList.add('not-sticky');
            this.component.classList.remove('condensed');
        }

        if (invisible) {
            this.component.classList.add('invisible');
        } else {
            this.component.classList.remove('invisible');
        }
    }

    thumbnailBehavior() {
        if (!this.attributes) {
            return;
        }

        let viewTop = 0;

        if (this.header) {
            viewTop += this.header.offsetHeight;
        }

        const attributesTop = this.attributes.getBoundingClientRect().top;

        if (attributesTop < viewTop) {
            this.productDetailInfo.classList.add('show-thumbnail');
        } else {
            this.productDetailInfo.classList.remove('show-thumbnail');
        }
    }
}

module.exports = function () {
    const boxElement = document.querySelector('.product-detail-col-cta');
    const headerElement = document.querySelector('.js-header');
    const attributesElement = document.querySelector('.product-detail-attributes');

    if (boxElement) {
        const box = new ProductCtaBox(
            boxElement,
            headerElement,
            attributesElement
        );
        box.readyCallback();
    }
};

'use strict';

var focusHelper = require('../components/focus');
const baseHelper = require('./base-helper');
const productGallery = require('./productGallery');
const productAccordion = require('./productAccordion');
const initYotpoReview = require('../components/initYotpoReview');
const productCtaBox = require('./productCtaBox');

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 */
function attributeSelect(selectedValueUrl, $productContainer) {
    if (selectedValueUrl) {
        $('body').trigger(
            'product:beforeAttributeSelect',
            { url: selectedValueUrl, container: $productContainer }
        );

        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
                let $result = $(data);
                let productURL = $result.closest('.product-wrapper').data('url');
                $productContainer.empty().html(data);
                productGallery();
                productAccordion();
                productCtaBox();
                initYotpoReview.refreshWidgets();
                window.history.pushState('', '', productURL);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
    return $('.add-to-cart-url').val();
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.choice-of-bonus-product');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body').spinner().start();

    if ($('#choose-bonus-product-modal').length !== 0) {
        $('#choose-bonus-product-modal').remove();
    }
    var bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="choose-bonus-product-modal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog choose-bonus-product-dialog" '
        + 'data-total-qty="' + data.maxBonusItems + '"'
        + 'data-UUID="' + data.uuid + '"'
        + 'data-pliUUID="' + data.pliUUID + '"'
        + 'data-addToCartUrl="' + data.addToCartUrl + '"'
        + 'data-pageStart="0"'
        + 'data-pageSize="' + data.pageSize + '"'
        + 'data-moreURL="' + data.showProductsUrlRuleBased + '"'
        + 'data-bonusChoiceRuleBased="' + data.bonusChoiceRuleBased + '">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <span class="">' + data.labels.selectprods + '</span>'
        + '    <button type="button" class="btn-close" data-bs-dismiss="modal">'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
    $('.modal-body').spinner().start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
            var parsedHtml = parseHtml(response.renderedTemplate);
            $('#choose-bonus-product-modal .modal-body').empty();
            $('#choose-bonus-product-modal .enter-message').text(response.enterDialogMessage);
            $('#choose-bonus-product-modal .modal-header .close .sr-only').text(response.closeButtonText);
            $('#choose-bonus-product-modal .modal-body').html(parsedHtml.body);
            $('#choose-bonus-product-modal .modal-footer').html(parsedHtml.footer);
            $('#choose-bonus-product-modal').modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    if (typeof window.checkResponseForEventDetails === 'function') {
        window.checkResponseForEventDetails(response);
    }

    $('.minicart').trigger('count:update', response);
    if (!response.error) {
        $('#addToCartConfirmationModal').modal('show');
    }
    var messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else {
        if (messageType === 'alert-success') {
            return;
        }

        const feedbackElement = $('.feedback-element');
        if (feedbackElement) {
            feedbackElement.append(`<div class="toast-message add-to-basket-message">
                                        <div class="toast-alert alert ${messageType} add-to-basket-alert px-0" role="alert">
                                            <div class="container">${response.message}</div>
                                        </div>
                                    </div>`);
        }

        setTimeout(function () {
            $('.add-to-basket-message').remove();
        }, 3000);
    }
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts() {
    var childProducts = [];
    $('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).find('.product-id').text(),
            quantity: parseInt($(this).find('label.quantity').data('quantity'), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var options = $productContainer
        .find('.product-option')
        .map(function () {
            var $elOption = $(this).find('.options-select');
            var urlValue = $elOption.val();
            var selectedValueId = $elOption.find('option[value="' + urlValue + '"]')
                .data('value-id');
            return {
                optionId: $(this).data('option-id'),
                selectedValueId: selectedValueId
            };
        }).toArray();

    var optionsRadio = $productContainer
        .find('.product-option-radio')
        .map(function () {
            var $elRadio = $(this).find('.js-options-radio:checked');
            var selectedValueId = $elRadio.data('value-id');
            return {
                optionId: $(this).data('option-id'),
                selectedValueId: selectedValueId
            };
        }).toArray();

    var optionsCombined = options.concat(optionsRadio);

    return JSON.stringify(optionsCombined);
}

/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function miniCartReportingUrl(url) {
    if (url) {
        $.ajax({
            url: url,
            method: 'GET',
            success: function () {
                // reporting urls hit on the server
            },
            error: function () {
                // no reporting urls hit on the server
            }
        });
    }
}

function triggerVariantProductSelector(context, e) {
    var $productContainer = $(context).closest('.product-details-js');
    if (!$productContainer.length) {
        $productContainer = $(context).closest('.modal-content').find('.product-quickview');
    }

    const selectedProductUrl = $(e.currentTarget).find('option:selected').data('url')
        ? $(e.currentTarget).find('option:selected').data('url')
        : $(e.currentTarget).data('url');

    if ($('.bundle-items', $productContainer).length === 0
        && $('.js-configurator-sections', $productContainer).length === 0
        && selectedProductUrl) {
        attributeSelect(
            selectedProductUrl,
            $productContainer
        );
    }
}

function variantImgHover() {
    const productVariantImgOverlayClass = 'swatch-img-overlay';

    const addVariantImgOverlay = (target, hoverImg, hoverAlt) => {
        if (!target || !hoverImg) {
            return;
        }

        const imgOverlayMarkup = `<div class="${productVariantImgOverlayClass}"><img alt="${hoverAlt}" src="${hoverImg}"></div>`;
        $(target).prepend(imgOverlayMarkup);
    };

    const removeVariantImgOverlays = () => {
        $(`.${productVariantImgOverlayClass}`).remove();
    };

    $(document).on('mouseenter', 'button.js-variant-selector', function () {
        const $variantButton = $(this);
        const variantButtonValue = $variantButton.data('variant-name');
        const variantButtonHoverImg = $variantButton.data('variant-hover-img');

        const $variant = $variantButton.closest('.product-variant');
        const $labelSelected = $variant.find('.js-variant-value-selected');
        const labelSelectedText = $labelSelected.data('original-text') || $labelSelected.text();

        const $variantHoverTargetWrapper = $($variant.data('variant-hover-target-wrapper'));
        const $variantHoverTarget = $variantHoverTargetWrapper.find($variant.data('variant-hover-target'));

        $labelSelected.data('original-text', labelSelectedText);
        $labelSelected.text(variantButtonValue);

        addVariantImgOverlay($variantHoverTarget, variantButtonHoverImg, variantButtonValue);
    });

    $(document).on('mouseleave', 'button.js-variant-selector', function () {
        const $variantButton = $(this);
        const $variant = $variantButton.closest('.product-variant');
        const $labelSelected = $variant.find('.js-variant-value-selected');

        const labelSelectedText = $labelSelected.data('original-text');
        $labelSelected.text(labelSelectedText);

        removeVariantImgOverlays();
    });
}

module.exports = {
    init: function () {
        variantImgHover();
    },
    attributeSelect: attributeSelect,
    methods: {
        editBonusProducts: function (data) {
            chooseBonusProducts(data);
        }
    },

    focusChooseBonusProductModal: function () {
        $('body').on('shown.bs.modal', '#choose-bonus-product-modal', function () {
            $('#choose-bonus-product-modal').siblings().attr('aria-hidden', 'true');
            $('#choose-bonus-product-modal .close').focus();
        });
    },

    onClosingChooseBonusProductModal: function () {
        $('body').on('hidden.bs.modal', '#choose-bonus-product-modal', function () {
            $('#choose-bonus-product-modal').siblings().attr('aria-hidden', 'false');
        });
    },

    trapChooseBonusProductModalFocus: function () {
        $('body').on('keydown', '#choose-bonus-product-modal', function (e) {
            var focusParams = {
                event: e,
                containerSelector: '#choose-bonus-product-modal',
                firstElementSelector: '.close',
                lastElementSelector: '.add-bonus-products'
            };
            focusHelper.setTabNextFocus(focusParams);
        });
    },

    colorAttribute: function () {
        $(document).on('click', '[data-attr="color"] button', function (e) {
            e.preventDefault();

            if ($(this).attr('disabled')) {
                return;
            }
            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-details-js');
            }

            attributeSelect($(this).attr('data-url'), $productContainer);
        });
    },

    selectAttribute: function () {
        $(document).on('change', 'select[class*="select-"], .options-select, .js-options-radio', function (e) {
            e.preventDefault();

            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-details-js');
            }
            attributeSelect($(this).attr('data-url'), $productContainer);
        });
    },

    availability: function () {
        $(document).on('change', '.quantity-select, select.js-variant-selector', function (e) {
            e.preventDefault();
            triggerVariantProductSelector(this, e);
        });

        $(document).on('click', 'button.js-variant-selector', function (e) {
            e.preventDefault();
            triggerVariantProductSelector(this, e);
        });
    },

    addToCart: function () {
        $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function () {
            var addToCartUrl;
            var pid;
            var pidsObj;
            var setPids;
            var cartButtonCollection = 'button.add-to-cart, button.add-to-cart-global';

            $('body').trigger('product:beforeAddToCart', [$(this)]);

            if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
                setPids = [];

                $('.product-detail').each(function () {
                    if (!$(this).hasClass('product-set-detail')) {
                        setPids.push({
                            pid: $(this).find('.product-id').text(),
                            qty: $(this).find('.quantity-select').val(),
                            options: getOptions($(this))
                        });
                    }
                });
                pidsObj = JSON.stringify(setPids);
            }

            pid = baseHelper.getPidValue($(this));

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
            }

            addToCartUrl = getAddToCartUrl();

            var form = {
                pid: pid,
                pidsObj: pidsObj,
                childProducts: getChildProducts(),
                configuratorProducts: JSON.stringify($('.configurator-sections').data('configurator-config')),
                lineItemId: $('.configurator-sections').data('line-item-id'),
                quantity: baseHelper.getQuantitySelected($(this))
            };
            var $loader = $(this).find('.js-add-to-cart-loader');

            if (!$('.bundle-item').length) {
                form.options = getOptions($productContainer);
            }

            $(this).trigger('updateAddToCartFormData', form);
            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success: function (data) {
                        handlePostCartAdd(data);
                        $('body').trigger('product:afterAddToCart', data);
                        $loader.addClass('d-none');
                        $(cartButtonCollection).prop('disabled', false);
                        miniCartReportingUrl(data.reportingURL);

                        const cartQuantityIndicatorEle = document.querySelector('.minicart-quantity');
                        if (data.quantityTotal) {
                            cartQuantityIndicatorEle?.classList?.remove('d-none');
                        } else {
                            cartQuantityIndicatorEle?.classList?.add('d-none');
                        }
                    },
                    error: function () {
                        $loader.addClass('d-none');
                        $(cartButtonCollection).prop('disabled', false);
                    }
                });
            }
        });
    },
    selectBonusProduct: function () {
        $(document).on('click', '.select-bonus-product', function () {
            var $choiceOfBonusProduct = $(this).parents('.choice-of-bonus-product');
            var pid = $(this).data('pid');
            var maxPids = $('.choose-bonus-product-dialog').data('total-qty');
            var submittedQty = parseInt($choiceOfBonusProduct.find('.bonus-quantity-select').val(), 10);
            var totalQty = 0;
            $.each($('#choose-bonus-product-modal .selected-bonus-products .selected-pid'), function () {
                totalQty += $(this).data('qty');
            });
            totalQty += submittedQty;
            var optionID = $choiceOfBonusProduct.find('.product-option').data('option-id');
            var valueId = $choiceOfBonusProduct.find('.options-select option:selected').data('valueId');
            if (totalQty <= maxPids) {
                var selectedBonusProductHtml = ''
                + '<div class="selected-pid row" '
                + 'data-pid="' + pid + '"'
                + 'data-qty="' + submittedQty + '"'
                + 'data-optionID="' + (optionID || '') + '"'
                + 'data-option-selected-value="' + (valueId || '') + '"'
                + '>'
                + '<div class="col-sm-11 col-9 bonus-product-name" >'
                + $choiceOfBonusProduct.find('.product-name').html()
                + '</div>'
                + '<div class="col-1"><span aria-hidden="true">&times;</span></div>'
                + '</div>';
                $('#choose-bonus-product-modal .selected-bonus-products').append(selectedBonusProductHtml);
                $('.pre-cart-products').html(totalQty);
                $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
            } else {
                $('.selected-bonus-products .bonus-summary').addClass('alert-danger');
            }
        });
    },
    removeBonusProduct: function () {
        $(document).on('click', '.selected-pid', function () {
            $(this).remove();
            var $selected = $('#choose-bonus-product-modal .selected-bonus-products .selected-pid');
            var count = 0;
            if ($selected.length) {
                $selected.each(function () {
                    count += parseInt($(this).data('qty'), 10);
                });
            }

            $('.pre-cart-products').html(count);
            $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
        });
    },
    enableBonusProductSelection: function () {
        $('body').on('bonusproduct:updateSelectButton', function (e, response) {
            $('button.select-bonus-product', response.$productContainer).attr(
                'disabled',
                (!response.product.readyToOrder || !response.product.available)
            );
            var pid = response.product.id;
            $('button.select-bonus-product', response.$productContainer).data('pid', pid);
        });
    },
    showMoreBonusProducts: function () {
        $(document).on('click', '.show-more-bonus-products', function () {
            var url = $(this).data('url');
            $('.modal-content').spinner().start();
            $.ajax({
                url: url,
                method: 'GET',
                success: function (html) {
                    var parsedHtml = parseHtml(html);
                    $('.modal-body').append(parsedHtml.body);
                    $('.show-more-bonus-products:first').remove();
                    $('.modal-content').spinner().stop();
                },
                error: function () {
                    $('.modal-content').spinner().stop();
                }
            });
        });
    },
    addBonusProductsToCart: function () {
        $(document).on('click', '.add-bonus-products', function () {
            var $readyToOrderBonusProducts = $('.choose-bonus-product-dialog .selected-pid');
            var queryString = '?pids=';
            var url = $('.choose-bonus-product-dialog').data('addtocarturl');
            var pidsObject = {
                bonusProducts: []
            };

            $.each($readyToOrderBonusProducts, function () {
                var qtyOption = parseInt($(this)
                    .data('qty'), 10);

                var option = null;
                if (qtyOption > 0) {
                    if ($(this).data('optionid') && $(this).data('option-selected-value')) {
                        option = {};
                        option.optionId = $(this).data('optionid');
                        option.productId = $(this).data('pid');
                        option.selectedValueId = $(this).data('option-selected-value');
                    }
                    pidsObject.bonusProducts.push({
                        pid: $(this).data('pid'),
                        qty: qtyOption,
                        options: [option]
                    });
                    pidsObject.totalQty = parseInt($('.pre-cart-products').html(), 10);
                }
            });
            queryString += JSON.stringify(pidsObject);
            queryString = queryString + '&uuid=' + $('.choose-bonus-product-dialog').data('uuid');
            queryString = queryString + '&pliuuid=' + $('.choose-bonus-product-dialog').data('pliuuid');
            $.spinner().start();
            $.ajax({
                url: url + queryString,
                method: 'POST',
                success: function (data) {
                    $.spinner().stop();
                    if (data.error) {
                        $('#choose-bonus-product-modal').modal('hide');
                        const feedbackElement = $('.feedback-element');
                        if (feedbackElement) {
                            feedbackElement.append(`<div class="toast-message add-to-basket-message">
                                                        <div class="toast-alert add-to-basket-alert text-center alert alert-danger" role="alert">
                                                            <div class="container">${data.errorMessage}</div>
                                                        </div>
                                                    </div>`);
                        }
                        setTimeout(function () {
                            $('.add-to-basket-message').remove();
                        }, 3000);
                    } else {
                        $('.configure-bonus-product-attributes').html(data);
                        $('.bonus-products-step2').removeClass('hidden-xl-down');
                        $('#choose-bonus-product-modal').modal('hide');
                        $('.minicart-quantity').html(data.totalQty);

                        const feedbackElement = $('.feedback-element');
                        if (feedbackElement) {
                            feedbackElement.append(`<div class="toast-message add-to-basket-message">
                                                        <div class="toast-alert add-to-basket-alert text-center alert alert-success" role="alert">
                                                            <div class="container">${data.msgSuccess}</div>
                                                        </div>
                                                    </div>`);
                        }
                        setTimeout(function () {
                            $('.add-to-basket-message').remove();
                            if ($('.cart-page').length) {
                                window.location.reload();
                            }
                        }, 3000);
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },

    getPidValue: baseHelper.getPidValue,
    getQuantitySelected: baseHelper.getQuantitySelected,
    miniCartReportingUrl: miniCartReportingUrl
};

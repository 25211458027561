'use strict';

module.exports = function (len = 5) {
    function productTilePlaceholder() {
        return `
            <div class="product-tile product-tile-placeholder placeholder-glow" aria-hidden="true">
                <div class="product-tile-row">
                    <div class="product-tile-col pos-0">
                        <div class="image-container">
                            <div class="ratio ratio-1x1">
                                <div class="placeholder"></div>
                            </div>
                        </div>
                    </div>
                    <div class="product-tile-col pos-1">
                        <div class="col-4 placeholder"></div>
                        <div class="col-12 placeholder"></div>
                        <div class="col-12 placeholder"></div>
                        <div class="col-8 placeholder"></div>
                        <div class="col-5 placeholder"></div>
                    </div>
                    <div class="product-tile-col pos-2">
                        <div class="col-7 placeholder mb-2"></div>
                        <div class="col-5 placeholder"></div>
                    </div>
                </div>
            </div>
        `;
    }

    return Array(len).fill('').map(() => productTilePlaceholder()).join('');
};
